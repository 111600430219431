<template>
  <!--涉及到多级路由参数-->
  <div class="page">

    <!--工具按钮集-->
    <div class="button-bar">
      <el-button
          type="primary"
          @click="submit()"
          :plain="true"
          size="mini"
          :disabled="ui.submitDisabled">
        <i class="fa fa-save i-gap"></i><span>立即确认</span>
        <span
            v-if="ui.count!=6 && ui.count!==0"
            class="timer"> {{ui.count}}
        </span>
      </el-button>
      <el-button
          type="warning"
          @click="resetForm('form')"
          :plain="true" size="mini">
        <i class="fa fa-undo"></i> 重置
      </el-button>
    </div>

    <!--表单滚动区域-->
    <div class="form-scroll">

      <el-form ref="form" :model="form" :label-width="ui.labelWidth">

        <template v-for="(item,index) in caption.tableFields"
                  v-if="!item.isFormHidden && item.formFormat!='password'">

          <el-form-item
              :label="item.comment"
              :required="item.required"
              :show-message="false"
              :prop="item.name">

            <template v-if="item.linkTableName!=''">

              <el-button @click=mySelect(item) :disabled="!form[item.name] || form[item.name]=='0'"
                         type="primary"
                         size="mini">
                <i :class="'fa '+(item.linkMultiRows?'fa-tag':'fa-tag')"></i> 我的选择
              </el-button>

              <el-button @click=goSelect(item) type="primary"
                         style="margin-left:5px;" :plain="true" size="mini"><i
                  :class="'fa '+(item.linkMultiRows?'fa-tags':'fa-tags')"></i> 去选择
              </el-button>

            </template>

            <template
                v-else-if="item.formFormat=='number' || item.formFormat=='datanumber' || item.formFormat=='money'">
              <el-input-number
                  v-model="form[item.name]"
                  size="small"
                  auto-complete="new-password"
                  autocomplete="off">
              </el-input-number>
            </template>

            <template v-else-if="item.formFormat=='datetime' || item.formFormat=='date' || item.formFormat=='time'">
              <el-date-picker
                  v-model="form[item.name]"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  clearable>
              </el-date-picker>
            </template>

            <template
                v-else-if="item.formFormat=='checkbox' || item.formFormat=='boolean' || item.formFormat=='check_mark'">
              <el-switch
                  :active-value="'1' || 'true' || 1 || true"
                  :inactive-value="'0' || 'false' || 0 || false"
                  v-model="form[item.name]">
              </el-switch>
            </template>

            <template v-else-if="item.formFormat=='filepath'">

              <el-button
                  @click=openFile(form[item.name])
                  :disabled="!form[item.name]"
                  :plain="false"
                  type="primary"
                  size="mini">
                <i class="fa fa-file-image-o"></i> 文件打开
              </el-button>

              <el-upload
                  :ref="item.name"
                  :action="uploadUrl"
                  :auto-upload="true"
                  :show-file-list="false"
                  :multiple="false"
                  style="display: inline-block;margin-left: 5px"
                  :limit="1">
                <el-button size="mini" type="primary" :plain="true">点击上传</el-button>
              </el-upload>

            </template>

            <template v-else-if="item.formFormat=='textarea' || item.formFormat=='editor'">
              <el-input
                  type="textarea"
                  v-model="form[item.name]"
                  :placeholder="'请输入'+item.comment"
                  auto-complete="new-password"
                  autocomplete="off">
              </el-input>
            </template>

            <template v-else-if="item.formFormat=='password'">
              <el-input
                  type="password"
                  v-model="form[item.name]"
                  :placeholder="'请输入'+item.comment"
                  auto-complete="new-password"
                  autocomplete="off"
                  clearable>
              </el-input>
            </template>

            <template v-else-if="item.formFormat=='scan'">
              <el-input
                  v-model="form[item.name]"
                  :placeholder="'请输入'+item.comment"
                  auto-complete="new-password"
                  autocomplete="off"
                  clearable>
              </el-input>
            </template>

            <template v-else>
              <el-input
                  v-model="form[item.name]"
                  :placeholder="'请输入'+item.comment"
                  auto-complete="new-password"
                  autocomplete="off"
                  clearable>
              </el-input>
            </template>

            <template v-if="item.linkTableName==''">
              <div class="fa-icon">
                <i v-if="icon[item.formFormat]!=null"
                   :class="'fa-icon fa '+icon[item.formFormat]"></i>
                <i v-else class="fa-icon fa fa-file-text-o"></i>
              </div>
            </template>

          </el-form-item>

        </template>

        <!--占位-->
        <el-form-item v-show="ui.placeHolder" class="place-holder">
          &nbsp;
        </el-form-item>

      </el-form>
    </div>


  </div>
</template>

<script>

import {getFormModel, getLoginUser, add, update} from '@/api/public.js'
import config from '/vue.config.js'
import eventBus from '../../static/js/eventBus.js';

export default {
  name: "updateUser",
  data() {
    return {
      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,
      uploadUrl: process.env.VUE_APP_BASE_API + "/manager/upload",

      // 文件上传
      fileList: [],

      // 界面参数
      ui: {
        labelWidth: '120px',
        placeHolder: false,
        submitDisabled: false,
        count: 6,
      },

      // 输入参数
      caption: {
        curFieldName: '',
        tableName: 'wl_user',
        tableAlias: 'wluser',
        tableTitle: '编辑资料',
        keyIdName: 'id',
        id: '',
        title: '编辑',
      },

      // 表单数据
      form: {},

      // 后缀图标
      icon: {
        "text": "fa-file-text-o",
        "String": "fa-file-text-o",
        "money": "fa-rmb",
        "email": "fa-envelope-o",
        "password": "fa-key",
        "telephone": "fa-phone",
        "scan": "fa-camera-retro",
        "sex": "fa-user-o",
        "select": "fa-list",
        "remark": "fa-edit",
        "date": "",
        "datetime": "",
        "time": "",
        "updatedate": "",
        "createdate": "",
        "mycreate": "fa-user-o",
        "number": "",
        "datanumber": "",
        "filepath": "",
        "checkbox": "",
        "boolean": "",
        "check_mark": "",
        "radio": "",
        "textarea": "",
        "editor": "",
      },

    }
  },
  created() {

    // 初始化
    this.initParm();
    if (this.caption.tableName) {
      this.initCaption();
    }
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  beforeUpdate() {

  },
  updated() {
    let element = document.querySelectorAll('.el-form-item:not(.place-holder)');
    if ((element.length % 2) == 1) {
      this.ui.placeHolder = true;
    }
  },
  activated() {

    eventBus.$off('reback');
    eventBus.$on('reback', (data) => {
      if (data['act'] == 'selectData') {
        this.setSelect(data)
      }
    });

  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    // 初始化参数
    initParm() {
      this.setResize();
    },

    // 初始化数据格式
    initCaption() {
      // 数据格式
      getFormModel(this.caption.tableName).then(res => {
        for (let key in res) {
          this.caption[key] = res[key];
        }

        let tableFields = this.caption.tableFields
        for (let index in tableFields) {

          let tableField = tableFields[index];
          let name = tableField["name"];
          let ret = name == "nickName";
          ret = ret || name == "avatar";
          ret = ret || name == "phonenumber";
          ret = ret || name == "email";
          ret = ret || name == "sex";
          ret = ret || name == "remark";
          if (!ret) {
            tableField["isFormHidden"] = true;
          }
        }

        this.initData();
      });
    },
    // 初始化数据
    initData() {
      // 数据
      getLoginUser().then(res => {
        this.form = res.sysUser;
        this.caption.id = this.form[this.caption.keyIdName];
        this.$forceUpdate();
      });
    },
    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.ui.labelWidth = '80px';
        } else {
          this.ui.labelWidth = '120px';
        }
      });
    },
    // 我的选择
    mySelect(item) {

      let linkTableName = item['linkTableName'];
      let value = this.form[item['name']];
      let linkUrl = "showTableMain?tableName=" + linkTableName + "&isMastePage=false&ids=" +
          value + "&inIds=true&method=mySelect";

      this.$router.push(linkUrl);
    },
    // 去选择
    goSelect(item) {

      let linkTableName = item['linkTableName'];
      let value = this.form[item['name']];
      let linkUrl = "showTableMain?tableName=" + linkTableName + "&isMastePage=false&ids=" +
          value + "&linkSaveBtn=true&multiselect=" + item['linkMultiRows'] + "&method=goSelect"

      if (item["dictType"]) {
        linkUrl = linkUrl + "&dictType=" + item["dictType"]
      }

      this.caption.curFieldName = item['name'];
      this.$router.push(linkUrl, res => {
      });

    },
    // 设置主键集，由穿越页面调用
    setSelect(res) {

      // 设置关联值
      this.form[this.caption.curFieldName] = res.ids + "";

      // 数组转JSON
      let fieldMeta = {};
      for (let index in this.caption['tableFields']) {
        let cm = this.caption['tableFields'][index];
        fieldMeta[cm.name] = cm;
      }

      // 数据联想功能，只联想字符串性质
      if (fieldMeta[this.caption.curFieldName]["linkTableName"] != this.caption.tableName && res["row"]) {

        for (let key in this.form) {

          let cm = fieldMeta[key];
          let retVal = res["row"][key];

          let canDo = key != this.caption.curFieldName;
          canDo = canDo && key != this.caption.keyIdName;
          canDo = canDo && cm.isFormHidden != true;
          canDo = canDo && cm.javaType == 'String';
          canDo = canDo && !this.form[key];
          canDo = canDo && retVal;

          if (canDo) this.form[key] = retVal;
        }
      }

      this.$forceUpdate();
    },
    // 提交
    submit() {

      // 倒计时
      this.ui.submitDisabled = true;

      let title = '信息提示';
      let type = 'warning';

      // 部分校验
      let tableFields = this.caption.tableFields
      for (let index in tableFields) {

        // 上传
        let tableField = tableFields[index];
        if (tableField['formFormat'] == "filepath") {
          let files = this.$refs[tableField['name']][0].uploadFiles
          if (files && files.length > 0) {
            this.form[tableField['name']] = files[0]["response"]["data"]["file"]
          }
        }

        // 非空判断弹窗
        if (tableField["required"] && !tableField["isFormHidden"] && !this.form[tableField['name']]) {
          this.$message({type: type, message: tableField['comment'] + '非空'});
          this.ui.submitDisabled = false;
          return;
        }
      }

      update(this.caption.tableName, {
        'id': this.caption.id,
        "pojo": this.form
      }).then(res => {

        if (res.result) {
          let message = "编辑资料成功，请重新登录！";
          this.$alert(message, title, {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({type: type, message: message});
            }
          });
        } else {
          this.$alert(res.message, title);
          this.$message({type: type, message: res.message});
        }

      });

      // 开启倒计时
      this.startTimer();
    },
    // 倒计时
    startTimer() {
      this.timer = setInterval(() => {
        if (this.ui.count > 0) {
          this.ui.count--;
        } else {
          clearInterval(this.timer); // 倒计时结束，停止计时器
          this.ui.submitDisabled = false;
          this.ui.count = 6;
        }
      }, 1000);
    },
    // 重置表单
    resetForm(formName) {

      if (this.caption.id) {
        this.$refs[formName].resetFields();
      } else {
        this.form = [];
      }

      this.$forceUpdate();
    },
    openFile(url) {

      let linkUrl = "showImageDetail?url=" + url + "&tableTitle=" + this.caption.tableTitle;
      this.$router.push(linkUrl);
    },
    // 输入回显
    inputChange(e) {
      this.$forceUpdate();
    },

  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {

  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

// 滚动条样式
#app .form-scroll::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);

  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--按钮栏--*/
.button-bar {

  margin: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

  .timer {

    margin-left: 5px;
    font-size: 12px;
    color: #dd524d;
    text-align: center;
  }
}

/*--表单滚动栏--*/
.form-scroll {

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;

  height: calc(100% - 150px);

  overflow-y: auto;

  background-image: url(../../static/images/bg_1.png);
  background-repeat: no-repeat;
  background-position: right bottom;

  .fa-icon {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: -1px;

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    opacity: 0.4;

    pointer-events: none;
  }

  .el-form-item {

    margin-bottom: 5px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    /*--2行--*/
    float: left;
    width: 50%;

    .el-form-item__label {
      white-space: nowrap;
      word-break: break-all;
      overflow: auto;
    }

  }

  textarea {
    position: absolute;
    z-index: 100;
    top: -40px;
    height: 40px;
  }

  textarea:focus {
    height: 105px;
  }

  .el-input__inner {
    padding-right: 35px;
  }

}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

.i-gap {
  margin-right: 5px;
}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }
  .place-holder {
    display: none;
  }

}

</style>